<template>
    <el-row :gutter="160">
        <el-col :span="24" style="text-align: left;">
            <el-button type="primary" icon="el-icon-search" @click="onQueryByCid" style="margin-left: 8px;">搜索玩家号
            </el-button>
            <el-button type="primary" icon="el-icon-search" @click="onQueryByOrderid" style="margin-left: 8px;">搜索订单号
            </el-button>
            <el-button type="success" icon="el-icon-headset" @click="onClickVirtual" style="margin-left: 8px;">
                虚拟充值
            </el-button>
        </el-col>
        <el-col :span="24">
            <el-table ref="multipleTable" :data="orderList" :height="tableHeight" border style="width: 100%"
                :row-class-name="tableRowClassName" :cell-style="{padding:4+'px'}" class="serverlist">
                <el-table-column v-for="item in labelArr" :key="item.prop" :prop="item.prop" :label="item.label"
                    show-overflow-tooltip></el-table-column>
                <el-table-column label="操作" width="200" v-if="examiningMail">
                    <template slot-scope="scope">
                        <el-button @click.native.prevent="onOrderResend(scope.row)" type="primary" icon="el-icon-thumb"
                            size="small" v-if="examiningMail">重发</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <el-pagination @current-change="handleCurrentChange" layout="total, prev, pager, next" background
                :page-size="20" :total="total">
            </el-pagination>
        </el-col>
        <!-- ========弹框BEGIN====== -->
        <el-dialog title="订单查询" :visible.sync="dialogForm" width="30%" center class="formparams"
            :close-on-click-modal="false">
            <el-form :model="form">
                <el-form-item :label="form.conditionLabel">
                    <el-input v-model="form.param" placeholder="请输入"></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button type="primary" icon="el-icon-check" @click="onQueryOrder">确 定</el-button>
            </div>
        </el-dialog>
        <!-- ========弹框END====== -->
        <!-- ========虚拟充值BEGIN====== -->
        <el-dialog title="虚拟充值管理" :visible.sync="dialogFormVirtual" width="100%" center class="formparams"
            :close-on-click-modal="false">
            <el-tabs v-model="tabVirtual" @tab-click="onVirtualTabClick">
                <el-tab-pane label="发货申请" name="virtualApply">
                    <el-form :model="formVirtual" size="mini">
                        <el-form-item label="角色id" label-width="80px">
                            <el-input v-model="formVirtual.pcid" placeholder="游戏里的角色id号" style="width: 200px;"></el-input>
                        </el-form-item>
                        
                        <el-form-item label="商品列表" label-width="80px">
                            <el-row  v-for="node in batnodes" :key="node.itemid">
                                <el-col :span="6">
                                    <el-form-item label="商品" label-width="60px">
                                        <el-select v-model="node.itemid" filterable placeholder="请选择" @change="onSelectGoodsid(node)" style="width: 100%;">
                                            <el-option v-for="item in goodslist" :key="item.itemid" :label="item.label" :value="item.itemid">
                                            </el-option>
                                        </el-select>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="18">
                                    <el-form-item label="自定义附加参数" label-width="120px">
                                        <el-input v-model="node.tip" placeholder="提示" style="width: 30%;color: #FF0000;" :disabled="true"></el-input>                            
                                        <el-input v-model="node.attach" placeholder="自定义参数" style="width: 60%;"></el-input>
                                        <el-button type="danger" icon="el-icon-delete" @click="onEraseBatNode(node)" style="width: 6%;margin-left: 4px;">删除</el-button>
                                    </el-form-item>
                                </el-col>
                            </el-row>
                        </el-form-item>
                        
                        <el-form-item label="添加条目" label-width="80px">
                            <el-button type="success" icon="el-icon-plus" @click="onAddBatNode" >商品</el-button>
                        </el-form-item>                        
                        <el-form-item label="充值备注" label-width="80px">
                            <el-input v-model="formVirtual.notice" placeholder="备注用途等"></el-input>
                        </el-form-item>
                        <el-form-item label="">
                            <el-button type="primary" icon="el-icon-check" @click="onApplyVirtual">确 定</el-button>
                        </el-form-item>
                    </el-form>
                </el-tab-pane>
                <el-tab-pane label="发货审批" name="virtualApproval">
                    <el-table ref="multipleTable" :data="virtualOrderList" height="500px" border style="width: 100%"
                        :row-class-name="tableRowClassName" :cell-style="{padding:4+'px'}" class="serverlist"
                        row-key="id" lazy :load="loadBatVOrder" :tree-props="{children: 'children', hasChildren: 'hasChildren'}"
                    >
                        <el-table-column prop="id" label="id" width="108" show-overflow-tooltip></el-table-column>
                        <el-table-column prop="createtime" label="时间" show-overflow-tooltip></el-table-column>
                        <el-table-column prop="orderid" label="虚拟订单号" width="280" show-overflow-tooltip></el-table-column>
                        <el-table-column prop="pcid" label="角色id" width="120" show-overflow-tooltip></el-table-column>
                        <el-table-column prop="label" label="商品id" show-overflow-tooltip></el-table-column>
                        <el-table-column prop="attach" label="附加参数" show-overflow-tooltip></el-table-column>
                        <el-table-column prop="notice" label="备注" show-overflow-tooltip></el-table-column>
                        <el-table-column label="审批状态" width="120" show-overflow-tooltip>
                            <template slot-scope="scope">
                                <span v-if="scope.row.examining===0" style="color: #0000ff;">待审批</span>                        
                                <span v-if="scope.row.examining===1" style="color: #00C957;">通过</span>
                                <span v-if="scope.row.examining===2" style="color: #FF0000;">拒绝</span>
                            </template>
                        </el-table-column>
                        <el-table-column prop="gamestate" label="发货状态" width="120" show-overflow-tooltip>
                        </el-table-column>  
                        <el-table-column label="操作" width="200">
                            <template slot-scope="scope">
                                <el-button @click.native.prevent="onVirtualApproval(scope.row)" type="primary"
                                    icon="el-icon-s-custom" size="small" v-if="showBtnExamining(scope.row)">审批</el-button>
                            </template>
                        </el-table-column>
                    </el-table>
                </el-tab-pane>
            </el-tabs>
        </el-dialog>
        <!-- ========弹框END====== -->
        <!-- ========弹框END====== -->        
        <el-dialog title="提示" :visible.sync="dialogLoading" width="24%" :close-on-click-modal="false" :show-close="false">
          <span>正在请求，请稍后...</span>
        </el-dialog>
    </el-row>
</template>

<script>
    import {
        mapGetters
    } from 'vuex'

    export default {
        data() {
            return {
                labelArr: [],
                orderList: [],
                formTitle: '',
                dialogLoading: false,
                /////////////////////////
                dialogForm: false,
                form: {
                    id: '',
                    param: '',
                    channel: '',
                    conditionLabel: '',
                },
                ///////////////////////
                dialogFormVirtual: false,
                tabVirtual: 'virtualApply',
                formVirtual: {
                    pcid: '',
                    itemid: '',
                    attach: '',
                    notice: '',
                    tip: '',
                },
                goodslist: [],
                virtualOrderList: [],
                batnodes: [],
                lazyMap: {}, // 保存懒加载记录
                ///////////////////////
                curPage: 1,
                total: 0,
                tableHeight: document.documentElement.clientHeight - 136 - 32,
            }
        },
        created: function() {
            this.handleCurrentChange(1); // 默认显示第一页
        },
        computed: {
            ...mapGetters(
                ['gameHttp','ownOpPermission']
            ),
            // 是否有邮件审批权限
            examiningMail: function() {
                return this.ownOpPermission("mail");
            },
        },
        methods: {
            onQueryByCid() {
                this.form.conditionLabel = '玩家ID'
                this.dialogForm = true
            },
            onQueryByOrderid() {
                this.form.conditionLabel = '渠道订单ID'
                this.dialogForm = true
            },
            // 查询列表
            onQueryOrder() {
                if (!this.form.param) {
                    this.$message.warning("请输入" + this.form.conditionLabel);
                    return;
                }
                let rqs = {};
                if ('玩家ID' == this.form.conditionLabel) {
                    rqs.cid = this.form.param;
                } else {
                    rqs.orderid = this.form.param;
                }
                this.gameHttp(3031, rqs).then((response) => {
                    this.dialogForm = false;
                    if (0 == response.data.errcode) {
                        this.form.channel = response.data.channel;
                        this.labelArr = response.data.labelarr;
                        this.orderList = response.data.list;
                        this.total = this.orderList.length;
                    }
                })
            },
            // 弹出重发确认框
            onOrderResend(row) {
                this.$confirm('是否重发[ ' + row.orderNo + ' ]订单?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.gameHttp(3032, {
                        id: row.id,
                        cid: row.cid,
                        channel: this.form.channel,
                    }).then((response) => {
                        if (0 == response.data.errcode) {
                            this.$message.success("提交成功")
                            if (this.form.channel) { // 查找的
                                this.onQueryOrder();
                            } else { // 列表的
                                this.onListOrder();
                            }
                        } else {
                            this.$message.error(response.data.errmsg)
                        }
                    })
                }).catch(() => {})
            },
            // 订单列表
            onListOrder() {
                this.gameHttp(3030, {
                    page: this.curPage
                }).then((response) => {
                    this.dialogForm = false;
                    if (0 == response.data.errcode) {
                        this.form.channel = response.data.channel;
                        this.labelArr = response.data.labelarr;
                        this.orderList = response.data.list;
                        this.total = response.data.total;
                        this.form.channel = ''; // 汇总列表的，没有channel信息，需要后台自己获取
                    }
                })
            },
            // 分页查看
            handleCurrentChange(page) {
                this.curPage = page;
                this.onListOrder();
            },
            // 打开虚拟发货
            onClickVirtual() {
                this.dialogFormVirtual = true;
                this.formVirtual.pcid = '';
                this.formVirtual.itemid = '';
                this.formVirtual.attach = '';
                this.formVirtual.notice = '';
                // 拉取商品列表
                this.gameHttp(3033, {}).then((response) => {
                    if (0 == response.data.errcode) {
                        this.goodslist = response.data.list;
                    }
                })
            },
            // 切换虚拟发货管理页签
            onVirtualTabClick(tab, examiningId) {
                console.log(tab.paneName);
                if ("virtualApproval" == tab.paneName) { // 拉取审批列表
                    this.gameHttp(3036, {}).then((response) => {
                        if (0 == response.data.errcode) {
                            this.virtualOrderList = response.data.list;
                        }
                        // 如果是审批的，再加载一下子项
                        if (examiningId) {
                            let lazyInfo = this.lazyMap[examiningId];// 更新懒加载，如果有
                            if (lazyInfo) {
                                this.loadBatVOrder(lazyInfo.row, lazyInfo.treeNode, lazyInfo.resolve);
                            }
                        }
                    });
                }
            },
            // 加载指定批量虚拟订单
            loadBatVOrder(row, treeNode, resolve) {
                this.gameHttp(3036, {bat_id:row.bat_id}).then((response) => {
                    if (0 == response.data.errcode) {
                        resolve(response.data.list);
                        this.lazyMap[row.id] = {row:row,treeNode:treeNode,resolve:resolve};
                    }
                });
            },
            
            // 拉取所选商品额外参数
            onSelectGoodsid(node) {
                this.dialogLoading = true;
                this.gameHttp(3037, {itemid:node.itemid}).then((response) => {
                    this.dialogLoading = false;
                    if (0 == response.data.errcode) {
                        node.attach = response.data.attach;
                        if (node.attach.length) {
                            node.tip = "自定义参数获取成功!";
                        } else {
                            node.tip = "暂无参数可获取，请对比发货表确定是否需要自定义参数";
                        }
                    } else {
                        this.$message.error('商品参数获取失败');
                    }
                })
            },
            // 添加批量商品
            onAddBatNode() {
                for (let i=0; i<this.batnodes.length; i++) {
                    if (!this.batnodes[i].itemid) {
                        this.$message.error('请补充完整前面的商品再添加'); return;
                    }
                }
                this.batnodes.push({itemid:'',tip:'',attach:''});
            },
            // 移除批量商品
            onEraseBatNode(row) {
                for (let i=0; i<this.batnodes.length; i++) {
                    if (row.itemid == this.batnodes[i].itemid) {
                        this.batnodes.splice(i, 1); break;
                    }
                }
            },
            // 请求虚拟发货
            onApplyVirtual() {
                if (!this.formVirtual.pcid) {
                    this.$message.error('请指定玩家id'); return;
                }
                if (0 == this.batnodes.length) {
                    this.$message.error('请添加商品'); return;
                }                
                for (let i=0; i<this.batnodes.length; i++) {
                    if (!this.batnodes[i].itemid) {
                        this.$message.error('请补充完整商品信息'); return;
                    }
                    this.batnodes[i].attach = this.batnodes[i].attach.trim();
                }
                if (!this.formVirtual.notice) {
                    this.$message.error('请添加备注'); return;
                }
                // 请求发货
                this.gameHttp(3034, {
                    cid:this.formVirtual.pcid.trim(),
                    bat:this.batnodes,
                    notice:this.formVirtual.notice.trim()
                }).then((response) => {
                    if (0 == response.data.errcode) {
                        this.batnodes = [];
                        this.formVirtual.pcid = '';
                        this.formVirtual.notice = '';
                        this.$message.success('提交成功');
                    } else {
                        this.$message.error(response.data.errmsg);
                    }
                })
            },
            // 显示审批按钮
            showBtnExamining(row) {
                return (this.examiningMail && 0==row.examining && row.id==row.bat_id );
            },
            // 审批虚拟发货
            onVirtualApproval(row) {
                this.$confirm('是否审批通过[ '+row.pcid+' ]发货?', '提示', {
                    confirmButtonText: '通过',
                    cancelButtonText: '拒绝',
                    type: 'warning'
                }).then(() => {
                    this.gameHttp(3035, {id:row.id,examining:1}).then((response) => {
                        if (0 == response.data.errcode) {
                            this.$message.success("审批完成")
                            this.onVirtualTabClick({paneName:"virtualApproval"}, row.id);
                        } else {
                            this.$message.error(response.data.errmsg);
                        }
                    });
                }).catch(() => {
                    this.gameHttp(3035, {id:row.id,examining:2}).then((response) => {
                        if (0 == response.data.errcode) {
                            this.$message.success("审批完成")
                            this.onVirtualTabClick({paneName:"virtualApproval"}, row.id);
                        } else {
                            this.$message.error(response.data.errmsg);
                        }
                    });                    
                });
            },
            // 数据表色彩间隔效果     
            tableRowClassName({
                rowIndex
            }) {
                if (0 == rowIndex % 2) {
                    return 'warning-row';
                } else {
                    return 'success-row';
                }
            },
        },
    }
</script>

<style>
    .serverlist.el-table .warning-row {
        background: #f5f7fa;
    }

    .serverlist.el-table .success-row {
        background: #ffffff;
    }

    .serverlist.el-table {
        margin-top: 16px;
    }

    .serverlist {
        font-size: 12px;
    }
</style>
